.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.App img {
  width: 100%;
}

.App .form-box {
  max-width: 380px;
  padding: 0 25px;
}

.form-box .error {
  margin: 30px 25px;
  padding: 15px;
  color: rgb(87, 71, 0);
  border: 1px solid rgb(255, 209, 6);
  background-color: rgba(255, 209, 6, 0.3);
}

.form-box .aviso {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.25rem;
  text-align: center;
}

.form-box .aviso .MuiButton-root {
  margin-top: 1em;
  width: 100%;
  color: currentColor;
  background-color: currentColor;
}

.form-box .aviso .MuiButton-label {
  color: #fff;
}

.form-box .error span {
  text-decoration: underline;
  text-decoration-color: rgb(70, 50, 0);
  color: rgb(70, 50, 0);
  cursor: pointer;
} 

.form-box .error span:hover {
  text-decoration-color: rgb(70, 50, 0, 0.8);  
  color: rgb(70, 50, 0, 0.8);
}

.App .loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.App > header {
  display: flex;
  margin: 40px auto 0;
  max-width: 100px;
  height: 140px;
}

.App > header svg {
  display: block;
  height: auto;
}

.App form {
  margin-bottom: 30px;
  padding: 40px 0;
  border-bottom: 1px dashed #CCC;
}

.App form button {
  margin-top: 20px !important;
}

.list {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin: 0 auto;
  min-width: 320px;
}

#list-header {
  box-sizing: border-box;
  padding: 10px 5px;
  width: 100%;
}

#list-header svg {
  font-size: 2rem;
}

.list h3 {
  margin: 0;
  /* padding: 0 25px; */
  font-size: 26px;
  font-weight: 500;
}

.list article {
  border-bottom: 1px dashed #CCC; 
  text-align: center;
  padding: 25px 0px 35px;
  width: 100%;
}

.list article h1 {
  opacity: 0.7;
  margin: 0;
  margin-bottom: 5px;
  font-weight: normal;
}

.list article h2 {
  margin: 0;
  font-weight: normal;
}

.list article button {
  margin-top: 20px;
}

.list .botao-google-agenda {
  display: block;
  margin-top: 20px;
  cursor: pointer;
  font-weight: bold;
}

.list .botao-google-agenda:hover {
  filter: brightness(50%);
}

.MuiCircularProgress-root {
  margin-top: 30px;
  width: 30px;
  height: 30px;
}